import PlayerTitleBar from '../../components/PlayerTitleBar.vue'
import PlayerPlaylistPanel from '../../components/PlayerPlaylistPanel.vue'
import PlayerPlaylistPanelMobile from '../../components/PlayerPlaylistPanelMobile.vue'
import PlayerControlsBars from '../../components/PlayerControlsBars.vue'
import PlayerInfoPanel from '../../components/PlayerInfoPanel.vue'
import PlayerSearchBar from '../../components/PlayerSearchBar.vue'
import {Howl} from 'howler'

export default {
    components: {
        PlayerTitleBar,
        PlayerPlaylistPanel,
        PlayerPlaylistPanelMobile,
        PlayerControlsBars,
        PlayerInfoPanel,
        PlayerSearchBar
    },
    data() {
        return {
            playlist: [],
            selectedTrack: null,
            index: 0,
            playing: false,
            loop: false,
            shuffle: false,
            seek: 0
        }
    },
    computed: {
        currentTrack() {
            return this.playlist[this.index]
        },
        progress() {
            if(this.currentTrack){
                if (this.currentTrack.howl.duration() === 0){ 
                    return 0
                } else {
                    return this.seek / this.currentTrack.howl.duration()
                }
            } else {
                return 0;
            }
        },
        getTrackInfo() {
            let artist = this.currentTrack ? this.currentTrack.artist : ""
            let title = this.currentTrack ? this.currentTrack.title : ""
            let seek = this.seek
            let duration = this.currentTrack ? this.currentTrack.howl.duration() : ""
            let image = this.currentTrack ? this.currentTrack.img : false;
            return {
                artist,
                title,
                seek,
                duration,
                image
            }
        },
        musicPlaying(){
            return this.currentTrack ? this.currentTrack.howl.playing() : false;
        }
    },
    watch: {
        playing(playing) {
            this.seek = this.currentTrack.howl.seek()
            let updateSeek
            if (playing) {
                updateSeek = setInterval(() => {
                    this.seek = this.currentTrack.howl.seek()
                }, 250)
            } else {
                clearInterval(updateSeek)
            }
        },
        index(){
            this.setSeek(0);
        }
    },
    beforeMount () {
        this.$store.dispatch('setSpinner', true);
    },
    async mounted(){
        try {
            await Promise.all([
                this.$store.dispatch("fetchMusicPlaylist")
            ]);
            this.playlist = this.formatPlaylist(this.$store.getters.getMusicPlaylist.tracks)
        } catch (error) {
            console.log(error);
            alert("Se ha producido un error al cargar la página. Compruebe su conexión a internet e inténtelo de nuevo. Si el error persiste, póngase en contacto con el Ministerio de Comunicación.")
        } finally {
            this.$store.dispatch('setSpinner', false);
        }
    },
    async beforeDestroy () {
        this.stop();
    },
    methods: {
        formatPlaylist(playlist) {
            let formatedPlaylist = [];
            if(playlist.length){
                playlist.map(track => {
                    formatedPlaylist.push({
                        title: track.title,
                        artist: track.meta.artist,
                        howl: null,
                        display: true,
                        link: track.src,
                        img: track.image.src ? track.image.src : false
                    })
                });
            }
            formatedPlaylist.forEach((track) => {
                track.howl = new Howl({
                    src: [track.link],
                    onend: () => {
                        if (this.loop) {
                            this.play(this.index)
                        } else {
                            this.skip('next')
                        }
                    }
                })
            })
            return formatedPlaylist;
        },
        selectTrack(track) {
            this.selectedTrack = track
        },
        play(index) {
            let selectedTrackIndex = this.playlist.findIndex(track => track === this.selectedTrack)

            if (typeof index === 'number') {
                if(index != this.index){
                    this.stop();
                }
            } else if (this.selectedTrack) {
                if (this.selectedTrack != this.currentTrack) {
                    this.stop()
                }
                index = selectedTrackIndex
            } else {
                index = this.index
            }

            let track = this.playlist[index].howl

            if (track.playing()) {
                return
            } else {
                track.play()
            }

            this.selectedTrack = this.playlist[index]
            this.playing = true
            this.index = index
        },
        pause() {
            this.currentTrack.howl.pause()
            this.playing = false
        },
        stop() {
            this.currentTrack.howl.stop()
            this.playing = false
        },
        skip(direction) {
            let index = 0,
                lastIndex = this.playlist.length - 1

            if (this.shuffle) {
                index = Math.round(Math.random() * lastIndex)
                while (index === this.index) {
                    index = Math.round(Math.random() * lastIndex)
                }
            } else if (direction === "next") {
                index = this.index + 1
                if (index >= this.playlist.length) {
                    index = 0
                }
            } else {
                index = this.index - 1
                if (index < 0) {
                    index = this.playlist.length - 1
                }
            }

            this.skipTo(index)
        },
        skipTo(index) {
            if (this.currentTrack) {
                this.currentTrack.howl.stop()
            }

            this.play(index)
        },
        toggleLoop(value) {
            this.loop = value
        },
        toggleShuffle(value) {
            this.shuffle = value
        },
        setSeek(percents) {
            let track = this.currentTrack.howl
            track.seek((track.duration() / 100) * percents)
        }
    }
}